const orange = "#F5D5CB"
const steel = "#DBDDEB"

import {value} from "master/rules/value.js"

function cmp(a, op, b)
{
  switch(op)
  {
       case "=": 
         return a == b
       break
       case "!=": 
         return a != b
       break
       case "<": 
         return Number(a) < Number(b)
       break
       case "<=": 
         return Number(a) <= Number(b)
       break
       case ">": 
         return Number(a) > Number(b)
       break
       case ">=": 
         return Number(a) >= Number(b)
       break
       default:
         return false
   }
}

export default [
 {
   title: "Сравнение",
   name: "cmp-const",
   template: "Факт {fact:f}/{path:i} {op:o} {value:i}",
   color: steel,
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     if (!params.op) throw new Error("'op' is not defined")
     return cmp(value(facts[params.fact], params.path), params.op, params.value)
   },
 }, 
 {
   title: "Сравнение с фактом",
   name: "cmp-fact",
   template: "Факт {fact:f}/{path:i} {op:o} {fact1:f}/{path1:f}",
   color: steel,
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     if (!params.fact1) throw new Error("'fact1' is not defined")
     if (!params.op) throw new Error("'op' is not defined")
     return cmp(value(facts[params.fact], params.path), params.op, value(facts[params.fact1], params.path1))
   },
 }, 
 {
   title: "Факт существует",
   name: "exists",
   template: "Факт {fact:f} существует",
   color: orange,
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     return !!facts[params.fact]
   },
 }, 
 {
   title: "Факт не существует",
   name: "not-exists",
   color: orange,
   template: "Факт {fact:f} не существует",
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     return !facts[params.fact]
   },
 }, 
]
