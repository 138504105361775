var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "well" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-2" },
        [
          _c("GenInput", {
            attrs: {
              size: 5,
              "label-size": 4,
              entity: "master-rule",
              attr: "priority",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.priority,
              callback: function($$v) {
                _vm.$set(_vm.model, "priority", $$v)
              },
              expression: "model.priority"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-5" },
        [
          _c("RuleCond", {
            attrs: {
              value: _vm.model.cond,
              entity: _vm.model.entity,
              facts: _vm.facts
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-5" },
        [
          _c("RuleAction", {
            attrs: {
              value: _vm.model.action,
              entity: _vm.model.entity,
              facts: _vm.facts
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }