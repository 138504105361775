<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>

import StdPage from "common/visual/StdPage.vue"
//import MasterNew    from "nas/page/NasNew.vue"
import MasterTable  from "master/page/MasterTable.vue"
import MasterEditor from "master/page/MasterEditor.vue"

import { eventBus } from "main"

export default {
  name: "MasterPage",
  title: "Мастера",
  mixins: [StdPage],
  
  data () {
    return {
      overlays: [
        {
          re: /\/master\/\d+/,
          component: MasterEditor,
        },
        {
          re: /\/master\/!new/,
//          component: MasterNew,
        },
        {
          re: /(?:)/,
          component: MasterTable,
        },
      ],
    }
  },
  
}

</script>
