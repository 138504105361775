import darsan from "darsan"
import {notifyError} from "common"
import {Rools, Rule} from "rools"

import actions from "master/rules/actions.js"
import conditions from "master/rules/conditions.js"

let debug = false
let extra = {}

export function setDebug(flag)
{
  debug = flag
}

export function setExtra(rec)
{
  extra = rec
}

function trace(...args)
{ 
  if (debug) console.log(...args)
}

export function compileRules(ruleDescr)
{
  const rules = ruleDescr.map(r =>
  {
    return new Rule({
      name: r.entity,
      when: facts => doConditions(facts, r.cond),
      then: facts => doActions(facts, r.action, extra),
    })
  })
  return rules
}

export function initFacts(factDescr, extra={})
{
  const facts = extra
  
  for (const f of factDescr)
  {
    if (f.initial)
    {
      facts[f.name] = f.initial
    }
  }
  
  trace(". initial facts", facts)
  
  return facts
}

export async function runRules(rules, facts)
{
  const rools = new Rools({logging: { error: true, debug: false}})
  try {
    await rools.register(rules)
    await rools.evaluate(facts)
  } catch(err)
  {
    notifyError(err)
  }
}
    
    async function doActions(facts, list)
    {
      for (const act of list)
      {
        const ad = actions.find(el => el.name==act.action)
        if (!ad) continue

        trace(". action", act)
        await ad.fn(facts, act, extra)
      }
    }
    
    function doCond(facts, cond)
    {
      const cd = conditions.find(el => el.name==cond.cond)
      if (!cd) return false
      
      const res = cd.fn(facts, cond)
      trace(". cond", cond, "->", res)
      return res
    }
    
    function doAnd(facts, cond)
    {
      trace(". doAnd")
      const res = cond.list.map(c => doConditions(facts, c))
      return res.reduce((acc, val) => acc && val, true)
    }

    function doOr(facts, cond)
    {
      trace(". doOr")
      const res = cond.list.map(c => doConditions(facts, c))
      return res.reduce((acc, val) => acc || val, false)
    }
    
    function doConditions(facts, cond)
    {
      if ("cond" in cond) return doCond(facts, cond)
      
      if ("connective" in cond)
      {
        if (cond.connective=="AND") 
        {
          return doAnd(facts, cond)
        }
        else if (cond.connective=="OR")
        {
          return doOr(facts, cond)
        }
        else
        {
          return false
        }
      }
    }

// при добавлении факта его не видно в редакторе правил (в перспективе надо перейти на vuex)
// нет добавления нового мастера
// при переходе от одного мастера к другому показываются правила от первого (не обновляется страница)
// редактирование в условиях поля /... не сохраняется, нет запроса put
// верстка занимает слишком много места