var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "slider", style: { left: _vm.sliderPos + "px" } },
        [
          _c("div", { staticClass: "slider-body well" }, [
            _c(
              "div",
              { staticStyle: { "overflow-y": "auto", "max-height": "100vh" } },
              [
                _c("div", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list" },
                    _vm._l(_vm.connectives, function(item) {
                      return _c(
                        "li",
                        {
                          style: { background: item.color },
                          attrs: { draggable: "true" },
                          on: {
                            dragstart: function($event) {
                              return _vm.dragConnective(item.name)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "cond list" },
                    _vm._l(_vm.conditions, function(item) {
                      return _c(
                        "li",
                        {
                          style: { background: item.color },
                          attrs: { draggable: "true" },
                          on: {
                            dragstart: function($event) {
                              return _vm.dragCond(item.name)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list" },
                    _vm._l(_vm.actions, function(item) {
                      return _c(
                        "li",
                        {
                          style: { background: item.color },
                          attrs: { draggable: "true" },
                          on: {
                            dragstart: function($event) {
                              return _vm.dragAction(item.name)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    }),
                    0
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "control", on: { click: _vm.sliderClick } },
            [_vm._v("Элементы")]
          )
        ]
      ),
      _vm._v(" "),
      _c("DynaList", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.ready,
            expression: "ready"
          }
        ],
        attrs: {
          apiDomain: "",
          apiTopic: "client",
          baseUrl: `${_vm.entity}/rule`,
          addText: "Новое правило"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(data) {
              return [
                _c("Rule", {
                  attrs: {
                    rec: data.parent,
                    apiPath: data.parent.entity,
                    facts: _vm.facts
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "label",
        { staticStyle: { "margin-top": "5px", "margin-bottom": "0px" } },
        [_vm._v("Связки")]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "label",
        { staticStyle: { "margin-top": "5px", "margin-bottom": "0px" } },
        [_vm._v("Условия")]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "label",
        { staticStyle: { "margin-top": "5px", "margin-bottom": "0px" } },
        [_vm._v("Действия")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }