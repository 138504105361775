<template>
  <Tabs :tabs=masterTabs :state=state />
</template>

<script>
import Tabs from "common/visual/Tabs.vue"

export default {

  name: "MasterEditor",
  
  components: {Tabs},
  
  props: {
    state: Object,
  },
  
  data() 
  {
    return {
      masterTabs: [
        { modPath: 'master/page/MasterInfo', name: "masterInfo", text: "Мастер", defaultTab: true, subpath: "" },
        { modPath: 'master/page/MasterFacts', text: "Факты", name: "masterFacts", defaultTab: true, subpath: "facts" },
        { modPath: 'master/page/MasterRules', text: "Правила", name: "workerКгдуы", defaultTab: true, subpath: "rules" },
      ],
    }
  },
}
</script>