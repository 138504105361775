const blue = "#daebff"
const red = "#ffdaeb"
const green = "#daffeb"
const yellow = "#D9CF9D"

import _ from "underscore"
import darsan from "darsan"

import {value} from "master/rules/value.js"
import sysconf from "sysconf"

function format(rec, str)
{
  return str.split(/(\{[^}]+\})/g)
  .map(el =>
  {
    const m = el.match(/\{([^}]+)\}/)
    if (!m) return el

    const expr = m[1]
    const pos = expr.indexOf("/")

    const name = pos==-1 ? expr : expr.substring(0, pos)
    const path = pos==-1 ? "" : expr.substring(pos+1)

    return value(rec[name], path)
  })
  .join("")
}

export default [
 {
   name: "fact.add",
   color: blue,
   title: "Добавить факт",
   template: "Добавить факт {fact:f}: {value:i}",
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     facts[params.fact] = params.value
   },
 }, 
 {
   name: "fact.update",
   color: blue,
   title: "Изменить факт",
   template: "Изменить факт {fact:f}: {value:i}",
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     facts[params.fact] = params.value
   },
 }, 
 {
   name: "fact.delete",
   color: blue,
   title: "Удалить факт",
   template: "Удалить факт {fact:f}",
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     delete facts[params.fact]
   },
 },
 {
   name: "fact.debug",
   color: blue,
   title: "Вывести все факты",
   template: "Вывести все факты",
   fn(facts, params)
   {
     console.log(facts)
   },
 },
 ////////////////////////////
 {
   name: "http.get",
   color: green,
   title: "Запрос API",
   template: "Запросить {topic:i} {path:i} и добавить как факт {fact:f}",
   async fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     const domain = sysconf.domainByUid(facts.uid)
     const res = await darsan.get(domain, params.topic, format(facts, params.path))
     facts[params.fact] = res
   },
 },
 {
   name: "http.getm",
   color: green,
   title: "Полный запрос API",
   template: "Запросить {topic:i} {path:i} и добавить как факт {fact:f} или как ошибку {err:f}",
   async fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     if (!params.err) throw new Error("'err' is not defined")
     try {
       const domain = sysconf.domainByUid(facts.uid)
       const res = await darsan.getm(domain, params.topic, format(facts, params.path))
       facts[params.fact] = res
     } catch(err)
     {
       facts[params.err] = err
     }
   },
 },
 /////////////////////////
 {
   name: "comp.extract",
   color: yellow,
   title: "Извлечь",
   template: "Извлечь из факта {fact:f}/{path:i} и добавить как факт {dst:f}",
   fn(facts, params)
   {
     if (!params.fact) throw new Error("'fact' is not defined")
     if (!params.dst) throw new Error("'dst' is not defined")
     facts[params.dst] = value(facts[params.fact], params.path)
   },
 },
 ///////////////////////////
 {
   name: "console.info",
   color: red,
   title: "Вывести",
   template: "Вывести  в раздел {section:i}: {value:I}",
   fn(facts, params, extra)
   {
     extra.print(params.section, "info", format(facts, params.value))
   }
 }, 
 {
   name: "console.list",
   color: red,
   title: "Вывести список",
   template: "Вывести для списка {fact:f}/{path:i} в раздел {section:i} заголовок {header:i} и элементы {template:I}",
   fn(facts, params, extra)
   {
     if (!params.fact) throw new Error("'fact' is not defined")

     const list = value(facts[params.fact], params.path)

     if (!_.isArray(list))
     {
       extra.print(params.section, "error", `Факт ${params.fact}/${params.path} не является списком`)
       return
     }
     
     extra.print(params.section, "header", params.header)
     extra.print(params.section, "list", list.map(el => format({it: el}, params.template)))
   }
 }, 
 {
   name: "console.error",
   color: red,
   title: "Ошибка",
   template: "Ошибка в разделе {section:i}: {value:I}",
   fn(facts, params, extra)
   {
     extra.print(params.section, "error", format(facts, params.value))
   }
 },
 {
   name: "send.telegram",
   color: red,
   title: "Отослать в Телеграм",
   template: "Отослать в Телеграм {id:i}: {value:I}",
   async fn(facts, params)
   {
     await darsan.post("", "client", "/srv/telegram/message", {to: params.id, message: format(facts, params.value)})
   }
 },
]
