<template>
  <div class="well">
  <div class="row">
    <div class="col-md-2">
      <GenInput :size=5 :label-size=4 entity="master-rule" attr=priority v-model="model.priority" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
    </div>
    <div class="col-md-5">
      <RuleCond :value=model.cond :entity=model.entity :facts=facts />
    </div>
    <div class="col-md-5">
      <RuleAction :value=model.action :entity=model.entity :facts=facts />
    </div>
  </div>
  </div>
</template>

<script>
import GenInput from "common/visual/cooper/GenInput.vue"
import RuleAction from "master/page/RuleAction.vue"
import RuleCond from "master/page/RuleCond.vue"

export default {

  name: "Rule",
  
  components: {GenInput, RuleAction, RuleCond},
  
  props: {
    rec: Object,
    facts: {
      type: Array,
      required: true,
    },
    apiDomain: String,
    apiTopic: String,
    apiPath: String,
  },

  data()
  {
    return {
      model: {},
    }
  },
  
  watch: {
    rec: {
      immediate: true,
      handler(val)
      {
        this.model = val
      }
    },
  },
  
}
</script>

<style scoped>
.well
{
  margin-bottom: 0em;
  padding: 5px;
}
</style>
