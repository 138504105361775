var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-2" },
      [
        _c("GenInput", {
          attrs: {
            size: 1,
            "label-size": 1,
            entity: "master-fact",
            attr: "name",
            apiDomain: _vm.apiDomain,
            apiTopic: "client",
            apiPath: _vm.apiPath
          },
          model: {
            value: _vm.model.name,
            callback: function($$v) {
              _vm.$set(_vm.model, "name", $$v)
            },
            expression: "model.name"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-4" },
      [
        _c("GenInput", {
          attrs: {
            size: 3,
            "label-size": 1,
            entity: "master-fact",
            attr: "description",
            apiDomain: _vm.apiDomain,
            apiTopic: "client",
            apiPath: _vm.apiPath
          },
          model: {
            value: _vm.model.description,
            callback: function($$v) {
              _vm.$set(_vm.model, "description", $$v)
            },
            expression: "model.description"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-4" },
      [
        _c("GenInput", {
          attrs: {
            size: 3,
            "label-size": 1,
            entity: "master-fact",
            attr: "initial",
            apiDomain: _vm.apiDomain,
            apiTopic: "client",
            apiPath: _vm.apiPath
          },
          model: {
            value: _vm.model.initial,
            callback: function($$v) {
              _vm.$set(_vm.model, "initial", $$v)
            },
            expression: "model.initial"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }