var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "main", on: { dragover: _vm.dragOver, drop: _vm.drop } },
    _vm._l(_vm.list, function(a, i) {
      return _c(
        "li",
        { style: { background: _vm.actionColor(a.action) } },
        [
          _vm._l(_vm.split(_vm.actionTemplate(a.action)), function(part) {
            return [
              part.substr(-2, 2) == ":i"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.list[i][part.substr(0, part.length - 2)],
                        expression: "list[i][part.substr(0,part.length-2)]"
                      }
                    ],
                    staticClass: "inp",
                    domProps: {
                      value: _vm.list[i][part.substr(0, part.length - 2)]
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.list[i],
                          part.substr(0, part.length - 2),
                          $event.target.value
                        )
                      }
                    }
                  })
                : part.substr(-2, 2) == ":I"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.list[i][part.substr(0, part.length - 2)],
                        expression: "list[i][part.substr(0,part.length-2)]"
                      }
                    ],
                    staticClass: "inp-long",
                    domProps: {
                      value: _vm.list[i][part.substr(0, part.length - 2)]
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.list[i],
                          part.substr(0, part.length - 2),
                          $event.target.value
                        )
                      }
                    }
                  })
                : part.substr(-2, 2) == ":f"
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.list[i][part.substr(0, part.length - 2)],
                          expression: "list[i][part.substr(0,part.length-2)]"
                        }
                      ],
                      staticClass: "inp",
                      class: {
                        wrong:
                          _vm.list[i][part.substr(0, part.length - 2)] == ""
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.list[i],
                            part.substr(0, part.length - 2),
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", {
                        attrs: { selected: "selected", value: "" }
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.facts, function(f) {
                        return _c("option", { domProps: { value: f.name } }, [
                          _vm._v(_vm._s(f.name))
                        ])
                      })
                    ],
                    2
                  )
                : [_vm._v("\n        " + _vm._s(part) + "\n      ")]
            ]
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "delete",
              on: {
                click: function($event) {
                  return _vm.deleteAction(i)
                }
              }
            },
            [_c("Icon", { attrs: { name: "trash" } })],
            1
          )
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }