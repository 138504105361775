var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { on: { dragover: _vm.dragOver, drop: _vm.drop } },
    [
      _c("Condition", {
        attrs: { facts: _vm.facts, value: _vm.tree },
        on: { delete: _vm.deleteNode, value: _vm.newValue, add: _vm.addNode }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }