<template>
  <div class="row">
    <div class="col-2">
      <GenInput :size=1 :label-size=1 entity="master-fact" attr=name v-model="model.name" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
    </div>
    <div class="col-4">
      <GenInput :size=3 :label-size=1 entity="master-fact" attr=description v-model="model.description" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
    </div>
    <div class="col-4">
      <GenInput :size=3 :label-size=1 entity="master-fact" attr=initial v-model="model.initial" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
    </div>
  </div>
</template>

<script>
import GenInput from "common/visual/cooper/GenInput.vue"

export default {

  name: "Fact",
  
  components: {GenInput},
  
  props: {
    rec: Object,
    apiDomain: String,
    apiTopic: String,
    apiPath: String,
  },

  data()
  {
    return {
      model: {},
    }
  },
  
  watch: {
    rec: {
      immediate: true,
      handler(val)
      {
        this.model = val
      }
    }
  },

}
</script>