<template>
    <ul class="main" @dragover=dragOver @drop=drop>
    <li v-for="(a,i) in list" :style="{background: actionColor(a.action)}" >
      <template v-for="part in split(actionTemplate(a.action))">
        <input v-if="part.substr(-2,2)==':i'" class="inp" v-model="list[i][part.substr(0,part.length-2)]" />
        <input v-else-if="part.substr(-2,2)==':I'" class="inp-long" v-model="list[i][part.substr(0,part.length-2)]" />
        <select v-else-if="part.substr(-2,2)==':f'" class="inp" v-model="list[i][part.substr(0,part.length-2)]" :class="{wrong: list[i][part.substr(0,part.length-2)]==''}">
            <option selected="selected" value=""></option>
            <option v-for="f in facts" :value="f.name">{{f.name}}</option>
        </select>
        <template v-else>
          {{part}}
        </template>
      </template>
      <span class="delete" @click="deleteAction(i)"><Icon name="trash"/></span>
    </li>
  </ul>
</template>

<script>
import _ from "underscore"
import darsan from "darsan"
import actions from "master/rules/actions.js"

export default {
  name: "RuleAction",
  
  props: {
    value: Array,
    facts: {
      type: Array,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
    apiDomain: {
      type: String,
      default: "",
    },
    apiTopic: {
      type: String,
      default: "client",
    },
  },
  
  data()
  {
    return {
      list: [],
    }
  },
  
  methods: {

    dragOver(event)
    {
      event.preventDefault()
    },

    drop(event)
    {
      event.preventDefault();
      const name = event.dataTransfer.getData("master-action")
      if (!name) return

      const i = actions.findIndex(el => el.name==name)
      if (i==-1) return
      
      const action = actions[i]

      const vars = [...action.template.matchAll(/\{(\w+):.\}/g)].map(el=>el[1])
      const rec = {action: name}
      for (const v of vars)
      {
        rec[v] = ""
      }
      
      this.list.push(rec)
    },
    
    deleteAction(i)
    {
      this.list.splice(i, 1)
    },
    
    split(str)
    {
      return str.split(/\{([:\w]+)\}/)
    },
    
    async save()
    {
      await darsan.patchJSON(this.apiDomain, this.apiTopic, this.entity, {action: this.list})
    },
    
    actionColor(name)
    {
      const act = actions.find(el => el.name==name)
      return act ? act.color : "white"
    },

    actionTemplate(name)
    {
      const act = actions.find(el => el.name==name)
      return act ? act.template : "???" + name
    },
    
  },
  
  computed: {
    actions()
    {
      return actions
    },
    
    debouncedSave()
    {
      return _.debounce(this.save, 500)
    }
  },

  
  watch: {

    value: {
      immediate: true,
      handler(val)
      {
        this.list = this.value
      }
    },
    
    list: {
      deep: true,
      handler(val)
      {
        this.debouncedSave()
      },
    }, 
  },  
    
}
</script>

<style scoped>
ul
{
    list-style: none;
    padding: 5px;
    margin: 0px;
}

ul li
{
  cursor: move;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0px;
  border: 1px solid lightgray;
}

.delete{
  display: inline-block;
  float: right;
  cursor: pointer;
}

.main {
  background: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  min-height: 3em;
}

.inp 
{
  min-width: 5em; 
  min-height: 2em; 
  border: 1px solid lightgray;
  background: white;
  margin: 0em 0.1em;
}

.inp-long
{
  min-width: 20em; 
  min-height: 2em; 
  border: 1px solid lightgray;
  background: white;
  margin: 0em 0.1em;
}

.wrong
{
  border: 1px solid darkred;
}
</style>