<template>
  <div class="row">
    <div class="col-md-2 text-center">
      <ol class="breadcrumb">
        <li><Icon name="fa-cubes" />&nbsp;&nbsp;<a href="/master">Мастера</a></li>
        <li class="active">{{state.id}}</li>
      </ol>
      <div class="btn-group-vertical" role="group">
        <a class="btn btn-default" :href="`/history/action/${state.id}`">История изменений</a>
        <a class="btn btn-default" @click=deleteMaster><Icon name="trash" /> Удалить</a>
      </div>
    </div>
    <div class="col-md-10">
      <div class="well">
      <div class="row">
         <div class="col-md-12">
            <GenInput :size=11 :label-size=1 entity="master" attr=name v-model="model.name" apiDomain="" apiTopic=client :apiPath=entity />
          </div>
      </div>
      <div class="row">
         <div class="col-md-12">
            <GenInput :size=11 :label-size=1 entity="master" attr=description v-model="model.description" apiDomain="" apiTopic=client :apiPath=entity />
          </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {goto} from "navigation"
import darsan from "darsan"
import GenInput from "common/visual/cooper/GenInput.vue"

export default {

  name: "MasterInfo",
  
  components: {GenInput},
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
      model: {},
    }
  },
  
  async activated()
  {
    this.model = await darsan.get("", "client", this.entity)
  },
  
  methods: {
    deleteMaster()
    {
      if (confirm("Вы действительно хотите удалить этот мастер?"))
      {
        darsan.delete("", "client", this.entity)
          .done( () =>
          {
            goto("/master")
          })
      }
    }
  },
  
  computed: {
    entity()
    {
      return "/master/" + this.state.id
    }
  },

}
</script>
