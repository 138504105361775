<template>
    <ul @dragover=dragOver @drop=drop>
      <Condition :facts=facts :value=tree @delete=deleteNode @value=newValue @add=addNode />
    </ul>
</template>

<script>
import _ from "underscore"
import darsan from "darsan"
import connectives from "master/rules/connectives.js"
import conditions from "master/rules/conditions.js"
import Condition from "master/page/Condition.vue"

export default {
  name: "RuleCond",
  
  components: {Condition},
  
  props: {
    value: Object,
    
    facts: {
      type: Array,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
    apiDomain: {
      type: String,
      default: "",
    },
    apiTopic: {
      type: String,
      default: "client",
    },
  },
  
  data()
  {
    return {
      cnt: 1,
      tree: {},
    }
  },

  methods: {

    dragOver(event)
    {
      event.preventDefault()
    },

    drop(event)
    {
      event.preventDefault()
      if (this.occupied) return
      
      const connective = event.dataTransfer.getData("master-connective")
      const cond = event.dataTransfer.getData("master-cond")

      let rec
      if (connective)
      {
        rec = this.makeNode("connective", connective)
      }
      else if (cond) 
      {
        rec = this.makeNode("cond", cond)
      }
      
      if (rec) this.tree = rec
    },
    
    deleteNode(id)
    {
      if (id==1)
      {
        this.cnt = 1
        this.tree = {}
      }
      else
      {
        this.deleteFrom(this.tree, id)
      }
    },
    
    deleteFrom(node, id)
    {
      if ('list' in node)
      {
        const i = node.list.findIndex(el => el.id==id)
        if (i==-1)
        {
          for (const rec of node.list)
          {
            this.deleteFrom(rec, id)
          }
        }
        else
        {
          node.list.splice(i, 1)
        }
      }
    },
    
    addNode(id, type, name)
    {
      const node = this.findNode(this.tree, id)
      if (node && 'connective' in node)
      {
        node.list.push(this.makeNode(type, name))
      }
    },
    
    makeNode(type, name)
    {
      if (type=="connective")
      {
        const c = connectives.find(el => el.name==name)
        if (!c) return null
        return {connective: name, list: [], id: this.cnt++}
      }
      else if (type=="cond")
      {
        const c = conditions.find(el => el.name==name)
        if (!c) return null

        const rec = {cond: name, id: this.cnt++}

        const vars = [...c.template.matchAll(/\{(\w+):.\}/g)].map(el=>el[1])
        for (const v of vars)
        {
          rec[v] = ""
        }
      
        return rec
      }
      
      return null
    },
    
    async save()
    {
      const data = structuredClone(this.tree)
      this.removeNumbers(data)
      await darsan.patchJSON(this.apiDomain, this.apiTopic, this.entity, {cond: data})
    },
    
    traverse(node, fn)
    {
      fn(node)

      if ('list' in node)
      {
        for (const rec of node.list)
        {
          this.traverse(rec, fn)
        }
      }
    },
    
    renumerate(tree)
    {
      this.traverse(tree, node => node.id = this.cnt++)
    },

    removeNumbers(tree)
    {
      this.traverse(tree, node => delete node.id)
    },
    
    newValue(id, field, val)
    {
      const node = this.findNode(this.tree, id)
      if (node) node[field] = val
    },
    
    findNode(node, id)
    {
      if (node.id==id) return node
      
      if ("list" in node)
      {
        for (const rec of node.list)
        {
          const found = this.findNode(rec, id)
          if (found) return found
        }
      }
      
      return null
    },
    
  },
  
  computed: {
    debouncedSave()
    {
      return _.debounce(this.save, 500)
    },
    
    occupied()
    {
      return Object.keys(this.tree).length
    },
    
  },

  
  watch: {

    value: {
      immediate: true,
      handler(val)
      {
        this.tree = this.value

        // Нумеруем все узлы и листья, чтобы потом на них ссылаться
        this.cnt = 1
        if (this.occupied) this.renumerate(this.tree)
      }
    },
    
    tree: {
      deep: true,
      handler(val)
      {
        this.debouncedSave()
      },
    }, 
  },  
    
}
</script>

<style scoped>
.delete{
  display: inline-block;
  float: right;
  cursor: pointer;
}

.main {
  background: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  min-height: 3em;
}

.wrong
{
  border: 2px solid darkred;
}

ul 
{
  margin: 0em;
}
</style>
