export default [
 {
   title: "И",
   name: "AND",
   color: "#90EEFA",
 },
 {
   title: "ИЛИ",
   name: "OR",
   color: "#E1BEE7",
 },
]
