<template>
    <DynaList :apiDomain="''" apiTopic=client :baseUrl="`${entity}/fact`" addText="Добавить факт" >
      <template v-slot="data">
        <Fact :rec=data.parent :apiPath=data.parent.entity />
      </template>
    </DynaList>
</template>

<script>
import DynaList from "common/visual/DynaList.vue"
import Fact from "master/page/Fact.vue"

export default {

  name: "MasterFacts",

  components: {DynaList, Fact},
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
      props: {
        state: Object,
      },
    }
  },
  
  computed: {
    entity()
    {
      return "/master/" + this.state.id
    }
  },

}
</script>
