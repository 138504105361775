var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-2 text-center" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [
            _c("Icon", { attrs: { name: "fa-cubes" } }),
            _vm._v("  "),
            _c("a", { attrs: { href: "/master" } }, [_vm._v("Мастера")])
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.state.id))])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn-group-vertical", attrs: { role: "group" } },
        [
          _c(
            "a",
            {
              staticClass: "btn btn-default",
              attrs: { href: `/history/action/${_vm.state.id}` }
            },
            [_vm._v("История изменений")]
          ),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "btn btn-default", on: { click: _vm.deleteMaster } },
            [_c("Icon", { attrs: { name: "trash" } }), _vm._v(" Удалить")],
            1
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-10" }, [
      _c("div", { staticClass: "well" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("GenInput", {
                attrs: {
                  size: 11,
                  "label-size": 1,
                  entity: "master",
                  attr: "name",
                  apiDomain: "",
                  apiTopic: "client",
                  apiPath: _vm.entity
                },
                model: {
                  value: _vm.model.name,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "name", $$v)
                  },
                  expression: "model.name"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("GenInput", {
                attrs: {
                  size: 11,
                  "label-size": 1,
                  entity: "master",
                  attr: "description",
                  apiDomain: "",
                  apiTopic: "client",
                  apiPath: _vm.entity
                },
                model: {
                  value: _vm.model.description,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "description", $$v)
                  },
                  expression: "model.description"
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }