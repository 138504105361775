const jp = require("jsonpath")

const ipoe = 
[
    {
        "cvlan": 443,
        "device": 138,
        "device_type": "pontree",
        "duration": 0,
        "ip": "100.70.105.81",
        "ipoe": "\/client\/57720\/ipoe\/2664",
        "last_seen": "2024-01-30 14:05:59+0300",
        "mac": "64:70:02:F8:D3:99",
        "nas": 98,
        "nas_if": "e2.3550.443",
        "nas_in_octets": "0x73BA7E2",
        "nas_in_packets": "0x20EFA",
        "nas_out_octets": "0x7232C0A",
        "nas_out_packets": "0x1D876",
        "pool": "hello",
        "port": 43,
        "service": "ipoe",
        "session": "ipoe1936",
        "start": "2024-01-30 12:00:59+0300",
        "svlan": 3550
    },
    {
        "cvlan": 443,
        "device": 138,
        "device_type": "pontree",
        "duration": 0,
        "ip": "100.70.105.81",
        "ipoe": "\/client\/57720\/ipoe\/2664",
        "last_seen": "2024-01-30 14:05:59+0300",
        "mac": "64:70:02:F8:D3:99",
        "nas": 98,
        "nas_if": "e2.3550.443",
        "nas_in_octets": "0x73BA7E3",
        "nas_in_packets": "0x20EFA",
        "nas_out_octets": "0x7232C0A",
        "nas_out_packets": "0x1D876",
        "pool": null,
        "port": 43,
        "service": "ipoe",
        "session": "ipoe1936",
        "start": "2024-01-30 12:00:59+0300",
        "svlan": 3550
    }
]

console.log(jp.query(ipoe, "$[?(@.pool)]"))