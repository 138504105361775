<template>
  <div>
    <Table ref=table name="masters" title="Список мастеров" :columns=columns
      apiTopic=client apiPath="/master" :state=state fetch="simple"
      :toolbar=toolbar @toolbar-click=toolbarClick
    >
      <template #delete="{rec}">
        <Icon name=trash @click.native=deleteMaster(rec) />
      </template>
      <template #name="{rec}">
        <a :href=rec.entity>{{rec.name}}</a>
      </template>

    </Table>
  </div>
</template>

<script>
import Table from "common/visual/Table.vue"
import darsan from "darsan"

export default {

  name: "MasterTable",
  
  components: {Table},
  
  data() {
    return {
        columns: [
          {
            name: "name",
            title: "Название",
            width: 10,
          },
          {
            name: "description",
            title: "Описание",
          },
          {
            name: "delete",
            title: "",
            width: 1,
          },
      ],
      state: "",
      
      toolbar: [
         {
            name: "add",
            icon: "plus",
            text: "Добавить",
          },
      ],
      
    }
  },
  
  activated()
  {
  },
  
  methods: {
  
    toolbarClick(msg)
    {
      if (msg.from=="add")
      {
        console.log("add")
      }
    },
    
    deleteMaster(rec)
    {
      if (confirm("Вы действительно хотите удалить этот мастер?"))
      {
        darsan.delete("", "client", rec.entity)
          .done( () =>
          {
            this.$refs.table.$emit("reloadTable")
          })
      }
    },
  },
}
</script>

<style scoped>
</scoped>
