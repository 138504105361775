<template>
    <ul class="main" >
    <li v-if="'cond' in value" :style="{background: condColor(value.cond)}">
      <template v-for="part in split(condTemplate(value.cond))">
        <input v-if="part.substr(-2,2)==':i'" class="inp" v-model="model[part.substr(0,part.length-2)]" @change="$emit('value', value.id, part.substr(0,part.length-2), $event.target.value)" />
        <select v-else-if="part.substr(-2,2)==':o'" :class="{wrong: !model[part.substr(0,part.length-2)]}" v-model="model[part.substr(0,part.length-2)]" @change="$emit('value', value.id, part.substr(0,part.length-2), $event.target.value)">
          <option selected="selected" value=""></option>
          <option v-for="op in operations" :value=op>{{op}}</option>
        </select>
        <select v-else-if="part.substr(-2,2)==':f'" class="inp" :class="{wrong: !model[part.substr(0,part.length-2)]}" v-model="model[part.substr(0,part.length-2)]" @change="$emit('value', value.id, part.substr(0,part.length-2), $event.target.value)" >
          <option selected="selected" value=""></option>
          <option v-for="f in facts" :value="f.name">{{f.name}}</option>
        </select>
        <template v-else>
          {{part}}
        </template>
      </template>
      <span class="delete" @click="$emit('delete', value.id)"><Icon name="trash"/></span>
    </li>
    <li v-if="'connective' in value">
      <div class="panel" :style="{'border-color': connectiveColor(value.connective)}">
         <div class="panel-heading" :style="{'border-color': connectiveColor(value.connective), background: connectiveColor(value.connective)}">
           <h4 class="panel-title">{{connectiveTitle(value.connective)}}<span class="delete" @click="$emit('delete', value.id)"><Icon name="trash"/></span></h4>
         </div>
         <div class="panel-body" @dragover=dragOver @drop=drop :class="{target: draggedOver}" @dragenter="draggedOver=true" @dragleave="draggedOver=false" >
           <Condition v-for="item in value.list" :key=item.id :facts=facts :value=item 
             @delete="(...args) => $emit('delete', ...args)" @add="(...args) => $emit('add', ...args)" @value="(...args) => $emit('value', ...args)" />
         </div>
      </div>
    </li>
  </ul>
</template>

<script>
import _ from "underscore"
import darsan from "darsan"
import connectives from "master/rules/connectives.js"
import conditions from "master/rules/conditions.js"

export default {
  name: "Condition",
  
  props: {
    value: Object,
    facts: {
      type: Array,
      required: true,
    },
  },
  
  data()
  {
    return {
      model: {},
      operations: ['=', '!=', '>', '>=', '<', '<='],
      draggedOver: false,
    }
  },
  
  methods: {
  
    split(str)
    {
      return str.split(/\{([:\w]+)\}/)
    },
    
    connectiveColor(name)
    {
      const item = connectives.find(el => el.name==name)
      return item ? item.color : "white"
    },

    connectiveTitle(name)
    {
      const item = connectives.find(el => el.name==name)
      return item ? item.title : item.name
    },

    condColor(name)
    {
      const item = conditions.find(el => el.name==name)
      return item ? item.color : "white"
    },

    condTemplate(name)
    {
      const item = conditions.find(el => el.name==name)
      return item ? item.template : "???" + name
    },
    
    dragOver()
    {
      event.preventDefault()
    },
    
    drop(event)
    {
      event.preventDefault()

      const connective = event.dataTransfer.getData("master-connective")
      const cond = event.dataTransfer.getData("master-cond")

      if (connective)
      {
        this.$emit("add", this.value.id, "connective", connective)
      }
      else if (cond)
      {
        this.$emit("add", this.value.id, "cond", cond)
      }

      this.draggedOver = false
      event.stopPropagation()
    },
      
  },
  
  watch: {

    value: {
      immediate: true,
      handler(val)
      {
        const rec = {}

        for (const k of Object.keys(this.value))
        {
          rec[k] = this.value[k]
        }

        this.model = rec
      }
    },
  },  
    
}
</script>

<style scoped>
ul
{
    list-style: none;
    padding: 0px;
    margin: 0px;
}

li
{
  padding: 0.5em;
}

.delete{
  display: inline-block;
  float: right;
  cursor: pointer;
  font-size: 10pt;
}

.main {
  background: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  min-height: 3em;
  margin: 0px;
  padding: 0px;
}

.inp 
{
  min-width: 10em; 
  min-height: 2em; 
  border: 1px solid lightgray;
  background: white;
  margin: 0em 0.1em;
}

.wrong
{
  border: 1px solid darkred;
}

.target
{
  background: repeating-linear-gradient(
  325deg,
  white,
  white 10px,
  lightgray 10px,
  lightgray 20px
);
}

.panel
{
  margin: 0em;
}

</style>
