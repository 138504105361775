var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DynaList", {
    attrs: {
      apiDomain: "",
      apiTopic: "client",
      baseUrl: `${_vm.entity}/fact`,
      addText: "Добавить факт"
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(data) {
          return [
            _c("Fact", {
              attrs: { rec: data.parent, apiPath: data.parent.entity }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }