var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Table", {
        ref: "table",
        attrs: {
          name: "masters",
          title: "Список мастеров",
          columns: _vm.columns,
          apiTopic: "client",
          apiPath: "/master",
          state: _vm.state,
          fetch: "simple",
          toolbar: _vm.toolbar
        },
        on: { "toolbar-click": _vm.toolbarClick },
        scopedSlots: _vm._u([
          {
            key: "delete",
            fn: function({ rec }) {
              return [
                _c("Icon", {
                  attrs: { name: "trash" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.deleteMaster(rec)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "name",
            fn: function({ rec }) {
              return [
                _c("a", { attrs: { href: rec.entity } }, [
                  _vm._v(_vm._s(rec.name))
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }